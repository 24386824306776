// ** React Imports
import React, {Fragment, useContext, useState} from "react"
import {useDispatch} from "react-redux"
import {useHistory, useParams} from "react-router-dom"
//************************************//
import { AbilityContext } from '../../context/context/Can'
//************************************//
import Register from "./Register"
import OtpVerify from "./Otp-verify"
import {_setAPIToken} from "../../Utils/API"
import {getAuth} from "firebase/auth"
import app from "../../fireBase-init"

const RegisterIndex = () => {
	const auth = getAuth(app)
	const [phoneNumber, setPhoneNumber] = useState('')
	const [email, setEmail] = useState('')
	const [name, setName] = useState('')
	const [otpPage, setOtpPage] = useState(false)
	const [password, setPassword] = useState('')
	const [rememberMe, setRememberMe] = useState(false)
	const [verificationId, setVerificationId] = useState('')

	const dispatch = useDispatch()
	const history = useHistory()
	const ability = useContext(AbilityContext)

	const _loginSuccessCallback = (res) => {
		// console.log(res, 'res')
		if (res?.data?.token) {
			const {data} = res
			dispatch({type:"USER_LOGIN", userData:data, token:data.token})
			_setAPIToken(data.token)
			sessionStorage.setItem("NORMAL_USER_TOKEN", JSON.stringify({email, token: data.token, user: data.user}))
			if (rememberMe) {
				localStorage.setItem("NORMAL_USER_TOKEN", JSON.stringify({email, token: data.token}))
			}
			if (data.user.abilities) {
				ability.update(data.user.abilities)
			}
			history.push('/')
		}
	}

	return (
		<Fragment>
			{!otpPage ? <Register states={{phoneNumber, setPhoneNumber, email, setEmail, name, setName, password, setPassword, rememberMe, setRememberMe, otpPage, setOtpPage, verificationId, setVerificationId, auth}} _loginSuccessCallback={_loginSuccessCallback} /> : <OtpVerify states={{phoneNumber, setPhoneNumber, email, setEmail, name, setName, password, setPassword, rememberMe, setRememberMe, verificationId, setVerificationId}} _loginSuccessCallback={_loginSuccessCallback} />}
		</Fragment>
	)
}
export default RegisterIndex
