import React from "react";

export default function CurrencyCardButton(props) {
  return (
    <div class="card">
      <button class="btn">
        <div class="container-fluid">
          <div class="row">
            <div class="col-4">
              <img
                style={{ height: "100%", objectFit: "contain" }}
                src={require("../assets/images/PNG/ethericon.png")}
                alt="ether"
              />
            </div>
            <div class="col-8">
              <div class="row">
                <span>{props.name}</span>
              </div>
              <div class="row">
                <span>${props.price}</span>
              </div>
            </div>
          </div>
        </div>
      </button>
    </div>
  );
}
