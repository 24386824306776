import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Switch, Route, Redirect } from "react-router-dom";
import Exchange from "../pages/exchange";
import Markets from "../pages/markets";
import Profile from "./profile";
import ForgotPassword from "../views/forgot-password";
import ProfileNew from "../views/profile-page";
import Wallet from "./wallet";
import Settings from "./settings";
// import Register from '../views/login/Register';
import LoginIndex from "../views/login/index";
import Reset from "./reset";
import OtpVerify from "../views/login/Otp-verify";
import OtpNumber from "./otp-number";
import Lock from "./lock";
import TermsAndConditions from "./terms-and-conditions";
import NewsDetails from "./news-details";
import Signup from "../views/register";
import Notfound from "./notfound";
import WebSdk from "../views/sumsub-kyc";
import { isUserLoggedIn } from "../Utils/utils";
import { useDispatch } from "react-redux";
import { _autoLogin } from "../redux/actions";

const Index = () => {
  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(_autoLogin(() => setReady(true)));
  }, []);

  /**
   ** Final Route Component Checks for Register & User Role and then redirects to the route
   */
  const FinalRoute = (props) => {
    // if (
    //   !isUserLoggedIn() && !props.authRoute
    // ) {
    //   return <Redirect to={'/login'} />
    // }
    if (isUserLoggedIn() && props.authRoute) {
      return <Redirect to={"/"} />;
    }
    // if(props.hasLayout){
    return (
      <Layout>
        <props.component />
      </Layout>
    );
    // }else{
    //   return (
    //     <props.component />
    //   )
    // }
  };
  return !ready ? (
    <></>
  ) : (
    <>
      <Switch>
        <Route exact path="/">
          <FinalRoute hasLayout={true} component={Exchange} />
        </Route>
        <Route path="/markets">
          <FinalRoute hasLayout={true} component={Markets} />
        </Route>
        <Route path="/profile">
          <FinalRoute hasLayout={true} component={ProfileNew} />
        </Route>
        <Route path="/wallet">
          <FinalRoute hasLayout={true} component={Wallet} />
        </Route>
        <Route path="/settings">
          <FinalRoute hasLayout={true} component={Settings} />
        </Route>
        <Route path="/login">
          <FinalRoute
            authRoute={true}
            hasLayout={true}
            component={LoginIndex}
          />
        </Route>
        <Route path="/signup">
          <FinalRoute authRoute={true} hasLayout={true} component={Signup} />
        </Route>
        <Route path="/forgot-password/:verification_code">
          <FinalRoute authRoute={true} hasLayout={true} component={ForgotPassword} />
        </Route>
        <Route path="/reset">
          <FinalRoute authRoute={true} hasLayout={false} component={Reset} />
        </Route>
        <Route path="/otp-verify">
          <FinalRoute
            authRoute={true}
            hasLayout={false}
            component={OtpVerify}
          />
        </Route>
        <Route path="/otp-number">
          <FinalRoute
            authRoute={true}
            hasLayout={false}
            component={OtpNumber}
          />
        </Route>
        <Route path="/lock">
          <FinalRoute hasLayout={true} component={Lock} />
        </Route>
        <Route path="/terms-and-conditions">
          <FinalRoute hasLayout={true} component={TermsAndConditions} />
        </Route>
        <Route path="/news-details">
          <FinalRoute hasLayout={true} component={NewsDetails} />
        </Route>
        <Route path="/kyc">
          <FinalRoute hasLayout={true} component={WebSdk} />
        </Route>
        <Route path="/notfound">
          <FinalRoute hasLayout={false} component={Notfound} />
        </Route>
      </Switch>
    </>
  );
};
export default Index;
