// ** Redux Imports
import { combineReducers } from 'redux'
import {userReducer} from "./userReducer";
import {appReducer} from "./appReducer";

// ** Basic Modules Reducers


// ** Reducers Imports

const rootReducer = combineReducers({
	user: userReducer,
	app: appReducer
})

export default rootReducer
