import {initializeApp} from 'firebase/app'
import {getAnalytics} from "firebase/analytics"

const firebaseConfig = {
    apiKey: "AIzaSyChFZv8yV8hFCRLEd1uhDJJbZZQWpdRics",

    authDomain: "test-firebase-184a0.firebaseapp.com",

    projectId: "test-firebase-184a0",

    storageBucket: "test-firebase-184a0.appspot.com",

    messagingSenderId: "60492286053",

    appId: "1:60492286053:web:c5d40f21add4fca4af1e8c",

    measurementId: "G-DMHLBDKYFW"


}
// Initialize Firebase
const app = initializeApp(firebaseConfig)

const analytics = getAnalytics(app)
export default app
