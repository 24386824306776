import React from "react";
import { Tabs, Tab } from "react-bootstrap";

export default class MarketHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trades: [],
    };

    const component = this;
    const ws = new WebSocket("wss://stream.binance.com:9443/ws/btcusdt@trade");
    ws.onmessage = function (event) {
      let data = JSON.parse(event.data);
      let temp = component.state.trades;
      temp.unshift({
        time: data.T,
        price: data.p,
        amount: data.q,
      });
      if (temp.length > 10) {
        temp.pop();
      }
      console.log(temp);
      component.setState({ trades: temp });
    };
  }
  render() {
    return (
      <>
        <div className="market-history">
          <Tabs defaultActiveKey="recent-trades">
            <Tab eventKey="recent-trades" title="Recent Trades">
              <table className="table">
                <thead>
                  <div className="container-fluid text-white-50 text-start">
                    <div class="row" style={{ borderBottom: "solid 1px" }}>
                      <div class="col-4">
                        <td style={{ fontSize: 12 }} className="border-0">
                          Time(UTC)
                        </td>
                      </div>
                      <div class="col-4">
                        <td style={{ fontSize: 12 }} className="border-0">
                          Price(USDT)
                        </td>
                      </div>
                      <div class="col-4 text-end">
                        <td style={{ fontSize: 12 }} className="border-0">
                          Amount(ETH)
                        </td>
                      </div>
                    </div>
                  </div>
                </thead>
                <tbody>
                  {this.state.trades.map((x) => {
                    return (
                      <div className="container-fluid text-white-50  text-end">
                        <div
                          class="row py-2"
                          style={{ borderBottom: "solid 1px" }}
                        >
                          <div class="col-4" style={{ textAlign: "end" }}>
                            {DateToTime(new Date(x.time))}
                          </div>
                          <div class="col-4" style={{ textAlign: "end" }}>
                            {parseFloat(x.price).toFixed(2)}
                          </div>
                          <div class="col-4" style={{ textAlign: "end" }}>
                            {parseFloat(x.amount).toFixed(5)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </tbody>
              </table>
            </Tab>
          </Tabs>
        </div>
      </>
    );
  }
}

function DateToTime(date) {
  return (
    date.getHours() +
    ":" +
    (date.getMinutes() < 10 ? "0" : "") +
    date.getMinutes() +
    ":" +
    (date.getSeconds() < 10 ? "0" : "") +
    date.getSeconds()
  );
}

function round(num, dec) {
  let sign = 1;
  if (num < 0) {
    sign = -1;
  }
  const expo = Math.pow(10, dec);
  return (sign * Math.round(num * expo)) / expo;
}
