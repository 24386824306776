import axios from "axios";
import { _logout } from "../redux/actions";
import { toast, Slide } from "react-toastify";
import {_loading} from "./utils";

export const API = axios.create({
  baseURL: process.env.REACT_APP_WENBIT_BASE_URL,
  headers: {
    dev: true,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  },
  timeout: 2 * 60 * 1000,
});

API.interceptors.request.use(
  function (config) {
    _loading(true)
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
API.interceptors.response.use(
  function (response) {
    _loading(false)
    return response.data;
  },
  function (error) {
    _loading(false)
    return Promise.reject(error.response);
  }
);

export const _setAPIToken = (token) => {
  API.defaults.headers["Authorization"] = `Bearer ${token}`;
};
