import { Link } from "react-router-dom";
import React, { useEffect, useContext, useState, useRef } from "react";
import { Center, NativeBaseProvider } from "native-base";
import app from "../../fireBase-init";
import PinInput from "react-pin-input";
import {
  getAuth,
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithCredential,
  signInWithPhoneNumber,
} from "firebase/auth";

// ** Custom Hooks
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

// ** Reactstrap Imports
import {
  Row,
  Col,
  CardTitle,
  CardText,
  Button,
  Form,
  Input,
  FormGroup,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "reactstrap";

// **
import { ButtonSpinner } from "../../components/ButtonSpinner";
// import {trans} from '@utils'
import { _loginTwoFactor, _registerTwoFactor } from "../../redux/actions";
import { Modal } from "reactstrap";
import RecaptchaModal from "../../components/RecaptchaModal";

const OtpVerify = (props) => {
  const { _loginSuccessCallback } = props;
  const {
    phoneNumber,
    setPhoneNumber,
    email,
    setEmail,
    name,
    setName,
    password,
    setPassword,
    rememberMe,
    setRememberMe,
    verificationId,
    setVerificationId,
  } = props.states;
  // ** Firebase
  const auth = getAuth(app);
  const [inputCode, setInputCode] = useState(0);
  const [id_token, setToken] = useState(null);
  const [recaptcha, setRecaptcha] = useState(true);
  const { register, errors, setValue, handleSubmit, control } = useForm();
  // const loading = useSelector(state => state?.app?.loading)
  const [modalState, setModalState] = useState(true);
  const [sendingTimer, setSendingTimer] = useState(150);

  useEffect(() => {
    setTimeout(() => {
      setSendingTimer(sendingTimer - 1);
    }, 1000);
  }, [sendingTimer]);

  const onSubmit = (data) => {
    _registerTwoFactor(
      {
        phone: phoneNumber,
        email,
        password,
        id_token,
        name,
      },
      (res) => {
        _loginSuccessCallback(res);
      },
      () => {}
    );
  };

  const loadCaptcha = async () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "visible",
        callback: (response) => {
          // console.log(response, 'response')
        },
      },
      auth
    );
    signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier)
      .then((confirmResult) => {
        setModalState(false);
        setVerificationId(confirmResult.verificationId);
      })
      .catch((error) => {
        // error
        console.log(error);
      })
      .finally(() => {
        document.getElementById("recaptcha-container").innerHTML = "";
      });
  };

  const handleResend = (e) => {
    if (sendingTimer > 0) return;
    setSendingTimer(150);
    setModalState(true);
    loadCaptcha();
  };

  return (
    <>
      <div className="vh-100 d-flex justify-content-center">
        <div className="form-access my-auto">
          <Form>
            <span className="mb-0">OTP Verification</span>
            <p className="text-center mb-4">
              One time code send on on your number
            </p>
            <FormGroup>
              <NativeBaseProvider>
                <Center>
                  <PinInput
                    length={6}
                    initialValue=""
                    style={{ width: "max-content" }}
                    onChange={(value) => setInputCode(value)}
                    type="numeric"
                    inputMode="number"
                    inputStyle={{
                      borderWidth: "0.5px",
                      borderColor: "#6e6b7b",
                      borderStyle: "solid",
                      borderRadius: 5,
                    }}
                    // inputFocusStyle={{ borderColor: 'blue'}}
                    onComplete={(value) => {
                      if (verificationId) {
                        const credential = PhoneAuthProvider.credential(
                          verificationId,
                          value
                        );
                        signInWithCredential(auth, credential).then((r) => {
                          setToken(r._tokenResponse.idToken);
                        });
                      }
                    }}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  />
                </Center>
              </NativeBaseProvider>
            </FormGroup>
            <Modal isOpen={modalState}>
              <RecaptchaModal
                loadCaptcha={loadCaptcha}
                setModalState={setModalState}
                phone={phoneNumber}
                setVerificationId={setVerificationId}
              />
            </Modal>
            <button
              onClick={handleSubmit(onSubmit)}
              className="btn btn-primary"
            >
              {/*{loading ? <ButtonSpinner/> : null}*/}
              Signup
            </button>
            <p className="text-center mt-2">
              <a style={{ color: "#0DBBBB" }} onClick={(e) => handleResend(e)}>
                Resend
                {sendingTimer > 0 ? <p>{sendingTimer}</p> : <span></span>}
              </a>
            </p>
          </Form>
        </div>
      </div>
    </>
  );
};
export default OtpVerify;
