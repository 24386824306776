import {store} from "../redux/storeConfig/store";

export const isUserLoggedIn = () => (store.getState().user.token)
export const getUserData = () => (store.getState().user?.userData)


//************************************//
export function _loading(start) {
	store.dispatch({type: start ? "APP_LOADING_START" : "APP_LOADING_END", payload: {}})
}
