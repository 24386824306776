import React, { useState, useContext, Fragment } from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import {
  Row,
  Col,
  CardTitle,
  CardText,
  Form,
  Input,
  FormGroup,
  Label,
  CustomInput,
  Button
} from 'reactstrap'
import InputPasswordToggle from '../../components/input-password-toggle'
import {isEmpty} from 'lodash'
//************************************//
import {ButtonSpinner} from '../../components/ButtonSpinner'
// import { trans} from '@utils'
//************************************//
import {_loginOtp} from '../../redux/actions'
import {getAuth} from "firebase/auth"
//************************************//
import app from "../../fireBase-init"
//************************************//


const Login = (props) => {
  const {_loginSuccessCallback} = props
  const {phoneNumber, setPhoneNumber, email, setEmail, password, setPassword, rememberMe, setRememberMe} = props.states
  // const loading = useSelector(state => state?.app?.loading)
  // ** FireBase
  const auth = getAuth(app)
  const { register, errors, setValue, handleSubmit } = useForm()
  register('login-email', { required: true, validate: value => value !== '' })
  register('login-password', { required: true, validate: value => value !== '' })

  const onSubmit = data => {
    if (isEmpty(errors)) {
      _loginOtp(
          {email, password},
          (res) => {
            _loginSuccessCallback(res)
          },
          () => {
          }
      )
    }
  }

  return (
      <div className="vh-100 d-flex justify-content-center">
          <div className="form-access my-auto">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Input
                  autoFocus
                  type='email'
                  value={email}
                  id='login-email'
                  name='login-email'
                  placeholder='Email'
                  htmlFor={'login-password'}
                  onChange={e => {
                    setEmail(e.target.value)
                    setValue('login-email', e.target.value)
                  }}
              />
            </FormGroup>
            <FormGroup className={'mt-25'}>
              <Input
                  value={password}
                  id='login-password'
                  name='login-password'
                  type='password'
                  placeholder='Password'
                  onChange={e => {
                    setPassword(e.target.value)
                    setValue('login-password', e.target.value)
                  }}
              />
            </FormGroup>
              <div style={{'marginTop': '5px'}} className="text-right">
                  <Link to="/reset">Forgot Password?</Link>
              </div>
                {/*<div style={{ display: 'flex', alignItems: 'center'}} className="custom-control custom-checkbox">*/}
                {/*    <input*/}
                {/*        type="checkbox"*/}
                {/*        className="custom-control-input"*/}
                {/*        id="form-checkbox"*/}
                {/*        onChange={e => setRememberMe(e.target.checked)}*/}
                {/*    />*/}
                {/*    <label style={{'marginTop': '4px', marginLeft: '4px'}} htmlFor="form-checkbox">*/}
                {/*        Remember me*/}
                {/*    </label>*/}
                {/*</div>*/}
            <button type='submit' className="btn btn-primary">
                {/*{ loading ? <ButtonSpinner/> : null}*/}
              login
            </button>
            <h2>
                Don't have an account? <Link to="/signup">Signup Here</Link>
            </h2>
          </Form>
          </div>
      </div>
  )
}
export default Login
