// ** React Imports
import React, {Fragment, useContext, useState} from "react"
import {useDispatch} from "react-redux"
import {useHistory} from "react-router-dom"
//************************************//
import { AbilityContext } from '../../context/context/Can'
//************************************//
import Login from "./Login"
import OtpVerify from "./Otp-verify"
import {_setAPIToken} from "../../Utils/API"
import {getAuth} from "firebase/auth"
import app from "../../fireBase-init"

const LoginIndex = () => {
	const auth = getAuth(app)
	const [phoneNumber, setPhoneNumber] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [rememberMe, setRememberMe] = useState(false)
	const [verificationId, setVerificationId] = useState('')

	const dispatch = useDispatch()
	const history = useHistory()

	const _loginSuccessCallback = (res) => {
		if (res?.data?.token) {
			const {data} = res
			dispatch({type:"USER_LOGIN", userData:data.user, token:data.token})
			_setAPIToken(data.token)
			sessionStorage.setItem("NORMAL_USER_TOKEN", JSON.stringify({email, token: data.token, user: data.user}))
			if (rememberMe) {
				localStorage.setItem("NORMAL_USER_TOKEN", JSON.stringify({email, token: data.token}))
			}

			history.push('/')
		} else if (res.next_step_code === 'USR_2FA_SMS') {
			setPhoneNumber(res.phone_number)
		}
	}

	return (
		<Fragment>
			{phoneNumber.length === 0 ? <Login states={{phoneNumber, setPhoneNumber, email, setEmail, password, setPassword, rememberMe, setRememberMe, verificationId, setVerificationId, auth}} _loginSuccessCallback={_loginSuccessCallback} /> : <OtpVerify  states={{phoneNumber, setPhoneNumber, email, setEmail, password, setPassword, rememberMe, setRememberMe, verificationId, setVerificationId}} _loginSuccessCallback={_loginSuccessCallback} />}
		</Fragment>
	)
}
export default LoginIndex
