import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../assets/css/HistoryOrder.css";

export default function HistoryOrder() {
  const user = useSelector((store) => store?.user?.userData);
  const isLoggedIn = user.id ? true : false;

  const LoginOrRegister = (
    <span className="no-data text-white-50">
      <Link className="hyperlink" to={"/login"}>
        Login
      </Link>{" "}
      or{" "}
      <Link className="hyperlink" to={"/signup"}>
        Register
      </Link>{" "}
      to trade
    </span>
  );
  return (
    <>
      <div className="market-history market-order mt15">
        <Tabs defaultActiveKey="open-orders">
          <Tab eventKey="open-orders" title="Open Orders">
            <ul className="d-flex justify-content-between market-order-item">
              <li>Time</li>
              <li>All pairs</li>
              <li>All Types</li>
              <li>Buy/Sell</li>
              <li>Price</li>
              <li>Amount</li>
              <li>Executed</li>
              <li>Unexecuted</li>
            </ul>
            {isLoggedIn ? <p>Show Data Here</p> : LoginOrRegister}
          </Tab>
          <Tab eventKey="closed-orders" title="Closed Orders">
            <ul className="d-flex justify-content-between market-order-item">
              <li>Time</li>
              <li>All pairs</li>
              <li>All Types</li>
              <li>Buy/Sell</li>
              <li>Price</li>
              <li>Amount</li>
              <li>Executed</li>
              <li>Unexecuted</li>
            </ul>
            <span className="no-data text-white-50">
              <Link to={"/login"}>Login</Link> or{" "}
              <Link to={"/signup"}>Register</Link> to trade
            </span>
          </Tab>
          <Tab eventKey="order-history" title="Order history">
            <ul className="d-flex justify-content-between market-order-item">
              <li>Time</li>
              <li>All pairs</li>
              <li>All Types</li>
              <li>Buy/Sell</li>
              <li>Price</li>
              <li>Amount</li>
              <li>Executed</li>
              <li>Unexecuted</li>
            </ul>
            <span className="no-data text-white-50">
              <Link to={"/login"}>Login</Link> or{" "}
              <Link to={"/signup"}>Register</Link> to trade
            </span>
          </Tab>
          <Tab eventKey="balance" title="Balance">
            <ul className="d-flex justify-content-between market-order-item">
              <li>Time</li>
              <li>All pairs</li>
              <li>All Types</li>
              <li>Buy/Sell</li>
              <li>Price</li>
              <li>Amount</li>
              <li>Executed</li>
              <li>Unexecuted</li>
            </ul>
            <span className="no-data text-white-50">
              <Link to={"/login"}>Login</Link> or{" "}
              <Link to={"/signup"}>Register</Link> to trade
            </span>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
