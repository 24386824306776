export const themeConfigs = {
	app: {
		appName: 'Wenbit ',
		menuAppName: 'Wenbit',
		appLogoImageLight: require('../assets/images/SVG/Wenbit-Logo-Dark.svg').default,
		appLogoImageDark: require('../assets/images/SVG/Wenbit-Logo-White.svg').default,
		menuLogoImageLight: require('../assets/images/SVG/Wenbit-Mark-Dark.svg').default,
		menuLogoImageDark: require('../assets/images/SVG/Wenbit-Mark-White.svg').default,
		images:{
			login:  require(`../assets/images/admin_dashboard_login_bg.png`).default,
			loginDark:  require(`../assets/images/admin_dashboard_login_bg.png`).default,
			register:  require(`../assets/images/admin_dashboard_login_bg.png`).default,
			registerDark:  require(`../assets/images/admin_dashboard_login_bg.png`).default,
			forgetPassword:  require(`../assets/images/admin_dashboard_login_bg.png`).default,
			forgetPasswordDark:  require(`../assets/images/admin_dashboard_login_bg.png`).default,
		},
		footerUrl:'#'
	},
}
