import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import {API} from "../Utils/API";
import {isEmpty} from "lodash";
import {_loginOtp} from "../redux/actions";
import {useForm} from "react-hook-form";
import {Form} from "reactstrap";

const Reset=()=> {
  const [email, setEmail] = useState('');
  const { register, errors, setValue, handleSubmit } = useForm()

  const onSubmit = data => {
    if (isEmpty(errors)) {
      API.post('user/forgot-password')
        .then(()=>alert('Send Successfully'))
        .catch(()=>alert('Something went wrong please try again'))
    }
  }
  return (
    <>
      <div className="vh-100 d-flex justify-content-center">
        <div className="form-access my-auto">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <span>Reset password</span>
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={e=>setEmail(e.target.value)}
              placeholder="Enter Your Email Address"
            />
            <button type="submit" className="btn btn-primary">
              Reset
            </button>
            <h2>
              Remember Password?
              <Link to="/login"> Sign in here</Link>
            </h2>
          </Form>
        </div>
      </div>
    </>
  );
}
export default Reset
