import React, { Component } from "react";
import HistoryOrder from "../components/HistoryOrder";
import MarketHistory from "../components/MarketHistory";
import MarketNews from "../components/MarketNews";
import MarketPairs from "../components/MarketPairs";
import MarketTrade from "../components/MarketTrade";
import OrderBook from "../components/OrderBook";
import QuickCurrenciesBar from "../components/QuickCurrenciesBar";
import TradingChart from "../components/TradingChart";
import TradingChartDark from "../components/TradingChartDark";
import { ThemeConsumer } from "../context/ThemeContext";

export default class exchange extends Component {
  render() {
    return (
      <>
        <div className="container-fluid mtb15 no-fluid">
          <div class="row">
            <QuickCurrenciesBar />
          </div>
          <div className="row sm-gutters">
            <div className="col-sm-12 col-md-3 d-none">
              <MarketPairs />
            </div>
            <div className="col-sm-12 col-md-9">
              <ThemeConsumer>
                {({ data }) => {
                  return data.theme === "light" ? (
                    <TradingChart />
                  ) : (
                    <TradingChartDark />
                  );
                }}
              </ThemeConsumer>
              <MarketTrade />
            </div>
            <div className="col-md-3">
              <OrderBook />
              <MarketHistory />
            </div>
            <div className="col-md-3 d-none">
              <MarketNews />
            </div>
            <div className="col-md-12">
              <HistoryOrder />
            </div>
          </div>
        </div>
      </>
    );
  }
}
