import React from "react";
import "../assets/css/QuickCurrenciesBar.scss";
import CurrencyCardButton from "./CurrencyCardButton";

export default function QuickCurrenciesBar() {
  const data = [
    {
      name: "Polygon",
      price: 11.23,
    },
    {
      name: "BNB",
      price: 3212.23,
    },
    {
      name: "Ethereum",
      price: 2111.23,
    },
    {
      name: "Bitcoin",
      price: 4432.23,
    },
    {
      name: "Polygon",
      price: 11.23,
    },
    {
      name: "BNB",
      price: 3212.23,
    },
    {
      name: "Ethereum",
      price: 2111.23,
    },
    {
      name: "Bitcoin",
      price: 4432.23,
    },
    {
      name: "Polygon",
      price: 11.23,
    },
    {
      name: "BNB",
      price: 3212.23,
    },
    {
      name: "Ethereum",
      price: 2111.23,
    },
    {
      name: "Bitcoin",
      price: 4432.23,
    },
  ];
  return (
    <div class="container-fluid horizontal-scrollable">
      <div class="row mx-2 mt-2 my-4 py-2">
        {data.map((x, i) => {
          return (
            <div key={i} class="col-2 px-1">
              <CurrencyCardButton name={x.name} price={x.price} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
