import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import React, { useEffect } from "react";
import app from "../fireBase-init";

export default function RecaptchaModal(props) {
  useEffect(() => {
    props.loadCaptcha();
  }, []);
  return (
    <div class="container-fluid text-center my-5">
      <div class="row">
        <div class="col-12">
          <h2>Verify Captcha</h2>
          <div
            style={{ textAlign: "-webkit-center", marginBottom: 10 }}
            id={"recaptcha-container"}
          />
        </div>
      </div>
    </div>
  );
}
