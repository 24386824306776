import React, { useState, useContext, Fragment } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../assets/css/phoneNumber.scss";
import PasswordStrengthBar from "react-password-strength-bar";
import {
  Row,
  Col,
  CardTitle,
  CardText,
  Form,
  Input,
  FormGroup,
  Label,
  CustomInput,
  Button,
} from "reactstrap";
import InputPasswordToggle from "../../components/input-password-toggle";
import { isEmpty } from "lodash";
//************************************//
import { ButtonSpinner } from "../../components/ButtonSpinner";
// import { trans} from '@utils'
//************************************//
import { _loginOtp } from "../../redux/actions";
import { getAuth } from "firebase/auth";
//************************************//
import app from "../../fireBase-init";
import PhoneNumberInput from "../../components/PhoneNumberInput";
//************************************//
const Register = (props) => {
  const { _loginSuccessCallback } = props;
  const {
    phoneNumber,
    setPhoneNumber,
    email,
    setEmail,
    name,
    setName,
    password,
    setPassword,
    rememberMe,
    setRememberMe,
    otpPage,
    setOtpPage,
  } = props.states;
  // const loading = useSelector(state => state?.app?.loading)
  // ** FireBase
  const auth = getAuth(app);
  const { register, errors, setValue, handleSubmit } = useForm();
  // register('signup-email', { required: true, validate: value => value !== '' })
  // register('signup-password', { required: true, validate: value => value !== '' })
  // register('signup-phone', { required: true, validate: value => value !== '' })
  // register('signup-name', { required: true, validate: value => value !== '' })

  const onSubmit = (data) => {
    // if (isEmpty(errors)) {
    // console.log(data, 'data')
    setOtpPage(true);
    // }
  };

  return (
    <div className="vh-100 d-flex justify-content-center">
      <div className="form-access my-auto">
        <span>Reset Password</span>
        <Form onSubmit={handleSubmit(onSubmit)}>

          <FormGroup>
            <Input
              autoFocus
              type="email"
              // value={email}
              id="signup-email"
              name="signup-email"
              placeholder="Email"
              htmlFor={"signup-password"}
              onChange={(e) => {
                setEmail(e.target.value);
                setValue("signup-email", e.target.value);
              }}
              // className={classnames({ 'is-invalid': errors['signup-email'] })}
            />
          </FormGroup>
          <FormGroup>
            <PhoneInput
              placeholder="Phone Number"
              country={"us"}
              // onChange={(phone) => this.setState({ phone })}
              onChange={(phone) => {
                phone = "+" + phone;
                console.log(phone);
                setPhoneNumber(phone);
                setValue("signup-phone", phone);
              }}
            />
          </FormGroup>
          <FormGroup className="mb-0">
            <Input
              // value={password}
              id="signup-password"
              name="signup-password"
              type="password"
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value);
                setValue("signup-password", e.target.value);
              }}
              // className={classnames({ 'is-invalid': errors['signup-password'] })}
            />
            <PasswordStrengthBar
              password={password}
              scoreWords={["Too Weak", "Weak", "Not Enough", "Good", "Strong"]}
              shortScoreWord={"Empty"}
              minLength={1}
            />
          </FormGroup>
          <FormGroup>
            <Input
              // value={password}
              id="signup-confirm-password"
              name="signup-confirm-password"
              placeholder="Confirm Password"
              type="password"
            />
          </FormGroup>
          {/*<div style={{display: 'flex', alignItems: 'center'}} className="custom-control custom-checkbox">*/}
          {/*    <input*/}
          {/*        type="checkbox"*/}
          {/*        className="custom-control-input"*/}
          {/*        id="form-checkbox"*/}
          {/*        onChange={e => setRememberMe(e.target.checked)}*/}
          {/*    />*/}
          {/*    <label style={{'marginTop': '4px', marginLeft: '4px'}} htmlFor="form-checkbox">*/}
          {/*        Remember me*/}
          {/*    </label>*/}
          {/*</div>*/}
          <h2>
            Already have an account?
            <Link to="/login"> Sign in here</Link>
          </h2>
          <button type="submit" className="btn btn-primary">
            Reset
          </button>
        </Form>
      </div>
    </div>
  );
};
export default Register;
