import axios from "axios";
import React, { useEffect, useState } from "react";
import { render } from "react-dom";

export default class OrderBook extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bids: [],
      maxBidtotal: 0,
      asks: [],
      maxAsktotal: 0,
      maxAsk: 0,
      previousMaxAsk: 0,
    };
  }

  componentDidMount() {
    const component = this;
    const ws = new WebSocket(
      "wss://stream.binance.com:9443/ws/btcusdt@depth20@1000ms"
    );
    ws.onmessage = function (event) {
      const json = JSON.parse(event.data);

      let tempBids = json.bids;
      tempBids.forEach((x) => {
        x.total = x[0] * x[1];
      });
      const totalsBids = tempBids.map((x) => x.total);
      const maxTotalBid = Math.max.apply(null, totalsBids);
      tempBids.forEach((x) => {
        x.percentage = round(x.total / maxTotalBid, 2) * 100;
      });
      tempBids = tempBids.sort((a, b) => a.percentage - b.percentage);

      let tempAsks = json.asks;
      tempAsks.forEach((x) => {
        x.total = x[0] * x[1];
      });
      const totalsAsks = tempAsks.map((x) => x.total);
      const maxTotalAsk = Math.max.apply(null, totalsAsks);
      tempAsks.forEach((x) => {
        x.percentage = round(x.total / maxTotalAsk, 2) * 100;
      });
      tempAsks = tempAsks.sort((a, b) => a.percentage - b.percentage);

      let test = tempAsks.map((x) => x[0]);

      component.setState({
        bids: tempBids,
        maxBidtotal: maxTotalBid,
        asks: tempAsks,
        maxAsktotal: maxTotalAsk,
        maxAsk: Math.max.apply(null, test),
        previousMaxAsk: component.state.maxAsk,
      });
    };
  }

  render() {
    return (
      <>
        <div className="order-book mb15">
          <h2 className="heading">Order Book</h2>
          <table className="table">
            <thead>
              <tr>
                <th>Price(BTC)</th>
                <th>Amount(USDT)</th>
                <th>Total(UDST)</th>
              </tr>
            </thead>
            <tbody>
              {this.state.bids.map((x, i) => {
                return (
                  <div key={i} class="container-fluid">
                    <div
                      class="row"
                      style={{
                        transition: "0.3s",
                        background:
                          "linear-gradient(to left, #ff231f63 " +
                          x.percentage +
                          "%, transparent " +
                          x.percentage +
                          "%)",
                      }}
                    >
                      <div class="col-6 text-danger">{round(x[0], 3)}</div>
                      <div class="col-3 text-white-50">{round(x[1], 3)}</div>
                      <div class="col-3 text-white-50">
                        {round(x[0] * x[1], 3)}
                      </div>
                    </div>
                  </div>
                );
              })}
            </tbody>
            <tbody className="ob-heading">
              <tr>
                <td>
                  <span>Last Price</span>
                  {this.state.maxAsk}
                </td>
                {/*<td>*/}
                {/*  <span>USD</span>*/}
                {/*  148.65*/}
                {/*</td>*/}
                <td className="red">
                  <span>Change</span>
                  {round(
                    (this.state.maxAsk - this.state.previousMaxAsk) /
                      this.state.previousMaxAsk,
                    5
                  )}
                  %
                </td>
              </tr>
            </tbody>
            <tbody>
              {this.state.asks.map((x, i) => {
                return (
                  <div key={i} class="container-fluid">
                    <div
                      class="row"
                      style={{
                        transition: "0.3s",
                        background:
                          "linear-gradient(to left, #26de8163 " +
                          x.percentage +
                          "%, transparent " +
                          x.percentage +
                          "%)",
                      }}
                    >
                      <div class="col-6 text-success">{round(x[0], 3)}</div>
                      {/*<div class="col-3 text-white-50">{round(x[1], 3)}</div>*/}
                      <div class="col-3 text-white-50">
                        {round(x[0] * x[1], 3)}
                      </div>
                    </div>
                  </div>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

function round(num, dec) {
  let sign = 1;
  if (num < 0) {
    sign = -1;
  }
  const expo = Math.pow(10, dec);
  return (sign * Math.round(num * expo)) / expo;
}
