import React, {useState, useContext, Fragment, useEffect} from 'react'
import {useDispatch, useSelector, connect} from 'react-redux'
import SumsubWebSdk from '@sumsub/websdk-react'
import {_getAccessToken, _getAccessTokenUser} from "../../redux/actions"

const WebSdk = (props)  => {
const dispatch = useDispatch()
    console.log(props, 'props')
    const { values, errors, touched, handleChange, setFieldValue } = props
    const [accessToken, setAccessToken] = useState(null)
    useEffect(() => {
        _getAccessTokenUser().then(e => setTimeout(() => setAccessToken(e?.data?.token), 350))
    }, [])
    const handler = () => _getAccessTokenUser().then(e => setTimeout(() => setAccessToken(e?.data?.token), 350))
        // setAccessToken(null)

    const config = {
        lang: "en"
    }
    const options = { addViewportTag: false, adaptIframeHeight: true }
    const messageHandler = (type, payload) => {
        // eslint-disable-next-line no-unused-expressions
        console.log(type, payload)
    }

    const errorHandler = () => console.log("onError")
    return (
        <div>
            { accessToken ? <SumsubWebSdk
                accessToken={accessToken}
                expirationHandler={handler}
                config={config}
                options={options}
                onMessage={messageHandler}
                onError={errorHandler}
            /> : <div> </div> }
        </div>
    )
}
const mapStateToProps = store => ({
    accessToken: store?.wenbit?.accessToken
})
export default connect(mapStateToProps)(WebSdk)
