import { API, _setAPIToken } from "../../Utils/API";
import _ from "lodash";
// import { store } from "@fwsrc/redux/storeConfig/store"
//************************************//
export const _login = ({ email, password }, callback, callbackErr) => {
  API.post("/login", { email, password })
    .then(function (res) {
      callback(res);
    })
    .catch(function (res) {
      callbackErr(res);
    });
};
//************************************//
export const _loginOtp = ({ email, password }, callback, callbackErr) => {
  API.post("user/v1/login", { email, password })
    .then(function (res) {
      callback(res);
    })
    .catch(function (res) {
      callbackErr(res);
    });
};
//************************************//
export const _loginTwoFactor = (
  { phone, email, password, id_token },
  callback,
  callbackErr
) => {
  API.post("user/v1/login/verify-2fa", { phone, email, password, id_token })
    .then(function (res) {
      callback(res);
    })
    .catch(function ({ data }) {
      callbackErr(data);
    });
};
//************************************//
export const _resetWithTwoFactor = (data, callback, callbackErr) => {
  API.post("user/v1/change-password", data)
    .then(function (res) {
      callback(res);
    })
    .catch(function ({ data }) {
      callbackErr(data);
    });
};
//************************************//
export const _registerTwoFactor = (data, callback, callbackErr) => {
  API.post("user/register", data)
    .then(function (res) {
      callback(res);
    })
    .catch(function ({ data }) {
      callbackErr(data);
    });
};
export const _signupTwoFactor = (
  { phone, email, password, id_token, name },
  callback,
  callbackErr
) => {
  API.post("user/v1/login/verify-2fa", {
    phone,
    email,
    password,
    id_token,
    name,
  })
    .then(function (res) {
      callback(res);
    })
    .catch(function ({ data }) {
      callbackErr(data);
    });
};
//************************************//
export const _register = (
  { firstName, lastName, email },
  callback,
  callbackErr
) => {
  API.post("/register", { first_name: firstName, last_name: lastName, email })
    .then(function ({ data }) {
      callback(data);
    })
    .catch(function ({ data }) {
      callbackErr(data.data);
    });
};
//************************************//
export const _getAccessTokenUser = async () => {
  const result = await API.post("/generate-user-kyc-token");
  const res = result;
  console.log(res, "res token");
  // store.dispatch({
  //     type: "ACCESS_TOKEN",
  //     data: res
  // })
  return res;
};
//************************************//
export const _forgetPassword = ({ email }, callback, callbackErr) => {
  API.post("/forget-password", { email })
    .then(function ({ data }) {
      callback(data);
    })
    .catch(function ({ data }) {
      callbackErr(data.data);
    });
};
//************************************//
export const _resetPassword = (
  { email, currentPassword, newPassword },
  callback,
  callbackErr
) => {
  API.post("/reset-password", {
    email,
    current_password: currentPassword,
    new_password: newPassword,
  })
    .then(function (res) {
      callback(res);
    })
    .catch(function ({ data }) {
      callbackErr(data.data);
    });
};
//************************************//
export const _changePassword = (data, callback, callbackErr) => {
  API.post("/user/account/change-password", { ...data })
    .then(function ({ data }) {
      callback(data);
    })
    .catch(function ({ data }) {
      callbackErr(data.data);
    });
};
//************************************//
export const _logout = () => {
  return (dispatch) => {
    // ** Remove user, accessToken & refreshToken from localStorage
    localStorage.removeItem("NORMAL_USER_TOKEN");
    sessionStorage.removeItem("NORMAL_USER_TOKEN");
    _setAPIToken("");
    dispatch({ type: "USER_LOGOUT" });
  };
};

//************************************//
export const _autoLogin = (callback) => {
  return (dispatch) => {
    const storageUserToken = JSON.parse(
      localStorage.getItem("NORMAL_USER_TOKEN")
    );
    const sessionUserToken = JSON.parse(
      sessionStorage.getItem("NORMAL_USER_TOKEN")
    );
    let user = sessionUserToken || false;
    if (user && user.token && user.user) {
      _setAPIToken(user.token);
      dispatch({ type: "USER_LOGIN", userData: user.user, token: user.token });
      // if (user.user.abilities) {
      //     ability.update(user.user.abilities)
      // }
      callback();
    } else if ((user = storageUserToken || false)) {
      if (user && user.token && user.email) {
        API.post("user/info", { email: user.email, token: user.token })
          .then(function (res) {
            if (res.data.token) {
              const { data } = res;
              sessionStorage.setItem(
                "NORMAL_USER_TOKEN",
                JSON.stringify({
                  email: user.email,
                  token: data.token,
                  user: data.user,
                })
              );
              _setAPIToken(data.token);
              dispatch({
                type: "USER_LOGIN",
                userData: data.user,
                token: data.token,
              });
              // if (data.user.abilities) {
              //     ability.update(data.user.abilities)
              // }
            }
            callback();
          })
          .catch(function (res) {
            localStorage.removeItem("NORMAL_USER_TOKEN");
            sessionStorage.removeItem("NORMAL_USER_TOKEN");
            callback();
          });
      }
    } else {
      callback();
    }
  };
};
//************************************//
export const _getMyProfile = (dispatch) => {
  API.get("/user/account/my-profile")
    .then(function ({ data }) {
      const userToken = JSON.parse(sessionStorage.getItem("NORMAL_USER_TOKEN"));
      userToken.user = { ...userToken.user, ...data.basic_info };
      sessionStorage.setItem("NORMAL_USER_TOKEN", JSON.stringify(userToken));
      dispatch({ type: "USER_MY_PROFILE", data });
    })
    .catch(function (res) {});
};
//************************************//
export const _changeBasicInfo = (data, callback, callbackErr) => {
  return (dispatch) => {
    API.post("/user/account/change-basic-info", data)
      .then(function ({ data }) {
        _getMyProfile(dispatch);
      })
      .catch(function ({ data }) {
        callbackErr(data.data);
      });
  };
};
//************************************//
export const _changeGeneralInfo = (data, callback, callbackErr) => {
  return (dispatch) => {
    API.post("/user/account/change-general-info", { ...data })
      .then(function ({ data }) {
        _getMyProfile(dispatch);
      })
      .catch(function ({ data }) {
        callbackErr(data.data);
      });
  };
};
//************************************//
export const _getUserList = ({ limit, page, sort, filter }, callback) => {
  API.get("/user/list", {
    params: {
      limit,
      page,
      sort,
      filter,
    },
  })
    .then(function ({ data }) {
      callback(data);
    })
    .catch(function (res) {});
};
//************************************//
export const _invite = (
  { firstName, lastName, email },
  callback,
  callbackErr
) => {
  API.post("/user/invite", {
    first_name: firstName,
    last_name: lastName,
    email,
  })
    .then(function ({ data }) {
      callback(data);
    })
    .catch(function ({ data }) {
      callbackErr(data.data);
    });
};
//************************************//
export const _resendInvite = (id) => {
  API.post(`/user/${id}/resend-invite`)
    .then(function ({ data }) {})
    .catch(function ({ data }) {});
};
//************************************//
export const _deleteUser = (id, callback) => {
  API.delete(`/user/${id}`)
    .then(function ({ data }) {
      callback(data);
    })
    .catch(function ({ data }) {});
};
//************************************//
export const _editBasicInfo = (
  { id, firstName, lastName },
  callback,
  callbackErr
) => {
  API.post(`/user/${id}/edit-basic-info`, {
    first_name: firstName,
    last_name: lastName,
  })
    .then(function ({ data }) {
      callback(data);
    })
    .catch(function ({ data }) {
      callbackErr(data.data);
    });
};
//************************************//
export const _getRoleList = ({ limit, page, sort, filter }, callback) => {
  API.get("/user/role", {
    params: {
      limit,
      page,
      sort,
      filter,
    },
  })
    .then(function ({ data }) {
      callback(data);
    })
    .catch(function (res) {});
};
//************************************//
export const _addRole = ({ name, isDefault }, callback, callbackErr) => {
  API.post("/user/role", { name, is_default: isDefault })
    .then(function ({ data }) {
      callback(data);
    })
    .catch(function ({ data }) {
      callbackErr(data.data);
    });
};
//************************************//
export const _editRole = ({ id, name, isDefault }, callback, callbackErr) => {
  API.patch(`/user/role/${id}`, { name, is_default: isDefault })
    .then(function ({ data }) {
      callback(data);
    })
    .catch(function ({ data }) {
      callbackErr(data.data);
    });
};
//************************************//
export const _deleteRole = (id, callback) => {
  API.delete(`/user/role/${id}`)
    .then(function ({ data }) {
      callback(data);
    })
    .catch(function ({ data }) {});
};
//************************************//
export const _getPermissions = ({}, callback) => {
  API.get("/user/role/get-permissions", {
    params: {},
  })
    .then(function ({ data }) {
      callback(data);
    })
    .catch(function (res) {});
};
//************************************//
export const _updatePermissions = (permissions, callback) => {
  API.post("/user/role/update-permissions", { permissions })
    .then(function ({ data }) {
      callback(data);
    })
    .catch(function () {});
};
//************************************//
export const _updateUserRole = ({ id, roles }, callback) => {
  API.post(`/user/${id}/update-roles`, { roles })
    .then(function ({ data }) {
      callback(data);
    })
    .catch(function ({ data }) {});
};
//************************************//
export const _updateUserData = (data) => {
  API.post(`/user/update`, data)
    .then(function ({ data }) {})
    .catch(function ({ data }) {});
};
export const _updateChangePassword = (data, callback, callbackErr) => {
  API.post(`/user/change-password`, data)
    .then(function ({ data }) {
      callback(data);
    })
    .catch(function ({ data }) {
      callbackErr(data.data);
    });
};
//************************************//
export const _searchRole = async (q = "") => {
  const res = await API.get("/user/role/search", {
    params: {
      limit: 20,
      q,
    },
  });
  const result = _.map(res.data, (v, k) => {
    return { value: v.id, label: v.name_lang };
  });
  return result;
};
//************************************//
export const _updateUserStatus = (data, callback) => {
  API.post(`/user/${data.userId}/change-status`, data)
    .then(function ({ data }) {
      callback(data);
    })
    .catch(function ({ data }) {});
};
