import React, {useState, useContext, Fragment} from 'react'
import {Tab, Row, Col, Nav} from 'react-bootstrap';
import {connect} from "react-redux";
import {useForm} from "react-hook-form";
import classnames from "classnames"
import {
    CardTitle,
    CardText,
    Form,
    Input,
    FormGroup,
    Label,
    CustomInput,
    Button,
    FormFeedback
} from 'reactstrap'
import InputPasswordToggle from '../../components/input-password-toggle'
import {_updateChangePassword, _updateUserData} from "../../redux/actions";
import {yupResolver} from "@hookform/resolvers/yup"
import * as yup from "yup"
import {isEmpty} from 'lodash'

const Profile = (props) => {
    console.log(props.userData)
    const [fullName, setFullName] = useState(props.userData.name)
    const [email, setEmail] = useState(props.userData.email)
    const [phoneNumber, setPhoneNumber] = useState(props.userData.phone)
    const [language, setLanguage] = useState(props.userData.language_id)
    const [currency, setCurrency] = useState(props.userData.currency_id)
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [valErrors, setValErrors] = useState({})
    const ValidationSchema = yup.object().shape({
        current_password: yup.string().required(),
        new_password: yup.string().min(6).required(),
        confirm_password: yup.string().oneOf([yup.ref('new_password'), null], 'password does not match')
    })

    const {
        register: register2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
    } = useForm({
        mode: "onSubmit",
    })
    const defaultValues = {
        current_password: '',
        new_password: '',
        confirm_password: ''
    }

    const { register, handleSubmit, control, setValue, errors, trigger} = useForm({ mode: 'onSubmit', resolver: yupResolver(ValidationSchema), defaultValues })

    const onSubmit = data => {
        _updateUserData(
            {
                    ...props.userData,
                    name: fullName,
                    email: email,
                    phone: phoneNumber,
                    language_id: language,
                    currency_id: currency
                }
        )
    }
    const onSubmitPassword = (data, e) => {
        if (!isEmpty(errors)) {
            return
        }
        setValErrors({})
        _updateChangePassword(
            {
                password: currentPassword,
                new_password: newPassword
            },
            (res) => {
                setCurrentPassword('')
                setNewPassword('')
                setConfirmPassword('')
            },
            (err) => {
                if (err) {
                    const arr = {}
                    for (const f in err) {
                        if (err[f] !== null) arr[f] = err[f][0]
                    }
                    setValErrors(arr)
                }
            }
            )
    }
        return (
            <>
                <div className="settings mtb15">
                    <div className="container-fluid">
                        <Tab.Container defaultActiveKey="profile">
                            <Row>
                                <Col lg={3}>
                                    <Nav variant="pills" className="settings-nav">
                                        <Nav.Item>
                                            <Nav.Link eventKey="profile">Profile</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="wallet">Wallet</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="settings">Settings</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col lg={9}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="profile">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">General Information</h5>
                                                    <div className="settings-profile">
                                                        <Form onSubmit={handleSubmit2(onSubmit)}>
                                                            <div className="form-row mt-4">
                                                                <div className="col-md-6">
                                                                    <FormGroup>
                                                                        <label htmlFor="name">Full name</label>
                                                                        <Input
                                                                            id="name"
                                                                            type="text"
                                                                            control={control}
                                                                            className="form-control"
                                                                            placeholder="Full Name"
                                                                            value={fullName}
                                                                            onChange={e => {
                                                                                setFullName(e.target.value)
                                                                                setValue('name', e.target.value)
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-md-6">
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <FormGroup>
                                                                        <label htmlFor="emailAddress">Email</label>
                                                                        <br/>
                                                                        <Label
                                                                            // id="emailAddress"
                                                                            control={control}
                                                                            htmlFor="emailAddress"
                                                                        >{props.userData.email}</Label>
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <FormGroup>
                                                                        <label htmlFor="phoneNumber">Phone</label>
                                                                        <br/>
                                                                        <Label
                                                                            htmlFor="phoneNumber"
                                                                            control={control}
                                                                        >{props.userData.phone}</Label>
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <FormGroup>
                                                                        <label htmlFor="selectLanguage">Language</label>
                                                                        <select
                                                                            value={language}
                                                                            control={control}
                                                                            onChange={e => {
                                                                                setLanguage(e.target.value)
                                                                                setValue('selectLanguage', e.target.value)
                                                                            }}
                                                                            id="selectLanguage"
                                                                            className="custom-select"
                                                                        >
                                                                            <option value='1'>English</option>
                                                                            <option value='2'>Spanish</option>
                                                                        </select>
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <FormGroup>
                                                                        <label htmlFor="selectCurrency">Currency</label>
                                                                        <select
                                                                            id="selectCurrency"
                                                                            control={control}
                                                                            className="custom-select"
                                                                            value={currency}
                                                                            onChange={e => {
                                                                                setCurrency(e.target.value)
                                                                                setValue('selectCurrency', e.target.value)
                                                                            }}
                                                                        >
                                                                            <option value='1'>USD</option>
                                                                            <option value='2'>EUR</option>
                                                                            <option value='3'>GBP</option>
                                                                            <option value='4'>CHF</option>
                                                                        </select>
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <input type="submit" value="Update"/>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">Security Information</h5>
                                                    <div className="settings-profile">
                                                        <Form onSubmit={handleSubmit(onSubmitPassword)}>
                                                            <div className="form-row">
                                                                <div className="col-md-6">
                                                                    <FormGroup>
                                                                        <Label className='form-label'
                                                                               for='current_password'>
                                                                            Current password
                                                                        </Label>
                                                                        <InputPasswordToggle
                                                                            id="current_password"
                                                                            name="current_password"
                                                                            className="form-control"
                                                                            placeholder="Enter your password"
                                                                            onChange={e => {
                                                                                setCurrentPassword(e.target.value)
                                                                                setValue('current_password', e.target.value)
                                                                            }}
                                                                            className={classnames({'is-invalid': errors['current_password']})}
                                                                            innerRef={register({
                                                                                required: true,
                                                                                validate: value => value !== ''
                                                                            })}
                                                                        />
                                                                        {Object.keys(valErrors).length && valErrors.current_password ? (
                                                                            <small
                                                                                className='text-danger'>{valErrors.current_password}</small>
                                                                        ) : null}
                                                                        {errors && errors.current_password &&
                                                                            <FormFeedback>{errors.current_password.message}</FormFeedback>}
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <FormGroup>
                                                                        <Label className='form-label'
                                                                               for='new_password'>New password</Label>
                                                                        <InputPasswordToggle
                                                                            id="new_password"
                                                                            name="new_password"
                                                                            className="form-control"
                                                                            placeholder="Enter new password"
                                                                            onChange={e => {
                                                                                setNewPassword(e.target.value)
                                                                                setValue('new_password', e.target.value)
                                                                            }}
                                                                            className={classnames({'is-invalid': errors['new_password']})}
                                                                            innerRef={register({
                                                                                required: true,
                                                                                validate: value => value !== ''
                                                                            })}
                                                                        />
                                                                        {Object.keys(valErrors).length && valErrors.new_password ? (
                                                                            <small
                                                                                className='text-danger'>{valErrors.new_password}</small>
                                                                        ) : null}
                                                                        {errors && errors.new_password &&
                                                                            <FormFeedback>{errors.new_password.message}</FormFeedback>}
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <FormGroup>
                                                                        <Label className='form-label'
                                                                               for='confirm_password'>Confirm
                                                                            password</Label>
                                                                        <InputPasswordToggle
                                                                            id="confirm_password"
                                                                            name="confirm_password"
                                                                            placeholder="Confirm password"
                                                                            className={classnames({'is-invalid': errors['confirm_password']})}
                                                                            innerRef={register({
                                                                                required: true,
                                                                                validate: value => value !== '' && value === newPassword
                                                                            })}
                                                                        />
                                                                        {errors && errors.confirm_password &&
                                                                            <FormFeedback>{errors.confirm_password.message}</FormFeedback>}
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <input type="submit" value="Update"/>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="wallet">
                                            <div className="wallet">
                                                <Row>
                                                    <Col lg={4}>
                                                        <Nav variant="pills" className="settings-nav">
                                                            <Nav.Item>
                                                                <Nav.Link
                                                                    eventKey="wallet_BTC"
                                                                    className="d-flex justify-content-between align-items-center active"
                                                                >
                                                                    <div className="d-flex">
                                                                        <img src={'img/icon/18.png'} alt="btc"/>
                                                                        <div>
                                                                            <h2>BTC</h2>
                                                                            <p>Bitcoin</p>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <h3>4.5484254</h3>
                                                                        <p className="text-right">
                                                                            <i className="icon ion-md-lock"></i>{' '}
                                                                            0.0000000
                                                                        </p>
                                                                    </div>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link
                                                                    eventKey="wallet_ETH"
                                                                    className="d-flex justify-content-between align-items-center"
                                                                >
                                                                    <div className="d-flex">
                                                                        <img src={'img/icon/1.png'} alt="btc"/>
                                                                        <div>
                                                                            <h2>ETH</h2>
                                                                            <p>Ethereum</p>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <h3>13.454845</h3>
                                                                        <p className="text-right">
                                                                            <i className="icon ion-md-lock"></i>{' '}
                                                                            0.0000000
                                                                        </p>
                                                                    </div>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link
                                                                    eventKey="wallet_BNB"
                                                                    className="d-flex justify-content-between align-items-center"
                                                                >
                                                                    <div className="d-flex">
                                                                        <img src={'img/icon/9.png'} alt="btc"/>
                                                                        <div>
                                                                            <h2>BNB</h2>
                                                                            <p>Binance</p>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <h3>35.4842458</h3>
                                                                        <p className="text-right">
                                                                            <i className="icon ion-md-lock"></i>{' '}
                                                                            0.0000000
                                                                        </p>
                                                                    </div>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link
                                                                    eventKey="wallet_TRX"
                                                                    className="d-flex justify-content-between align-items-center"
                                                                >
                                                                    <div className="d-flex">
                                                                        <img src={'img/icon/6.png'} alt="btc"/>
                                                                        <div>
                                                                            <h2>TRX</h2>
                                                                            <p>Tron</p>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <h3>4.458941</h3>
                                                                        <p className="text-right">
                                                                            <i className="icon ion-md-lock"></i>{' '}
                                                                            0.0000000
                                                                        </p>
                                                                    </div>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link
                                                                    eventKey="wallet_EOS"
                                                                    className="d-flex justify-content-between align-items-center"
                                                                >
                                                                    <div className="d-flex">
                                                                        <img src={'img/icon/2.png'} alt="btc"/>
                                                                        <div>
                                                                            <h2>EOS</h2>
                                                                            <p>Eosio</p>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <h3>33.478951</h3>
                                                                        <p className="text-right">
                                                                            <i className="icon ion-md-lock"></i>{' '}
                                                                            0.0000000
                                                                        </p>
                                                                    </div>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link
                                                                    eventKey="wallet_XMR"
                                                                    className="d-flex justify-content-between align-items-center"
                                                                >
                                                                    <div className="d-flex">
                                                                        <img src={'img/icon/7.png'} alt="btc"/>
                                                                        <div>
                                                                            <h2>XMR</h2>
                                                                            <p>Monero</p>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <h3>99.465975</h3>
                                                                        <p className="text-right">
                                                                            <i className="icon ion-md-lock"></i>{' '}
                                                                            0.0000000
                                                                        </p>
                                                                    </div>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link
                                                                    eventKey="wallet_KCS"
                                                                    className="d-flex justify-content-between align-items-center"
                                                                >
                                                                    <div className="d-flex">
                                                                        <img src={'img/icon/4.png'} alt="btc"/>
                                                                        <div>
                                                                            <h2>KCS</h2>
                                                                            <p>Kstarcoin</p>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <h3>114.57564</h3>
                                                                        <p className="text-right">
                                                                            <i className="icon ion-md-lock"></i>{' '}
                                                                            0.0000000
                                                                        </p>
                                                                    </div>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                    </Col>

                                                    <Col lg={8}>
                                                        <div className="tab-content">
                                                            <div
                                                                className="tab-pane fade show active"
                                                                id="coinBTC"
                                                                role="tabpanel"
                                                            >
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">Balances</h5>
                                                                        <ul>
                                                                            <li className="d-flex justify-content-between align-items-center">
                                                                                <div
                                                                                    className="d-flex align-items-center">
                                                                                    <i className="icon ion-md-cash"></i>
                                                                                    <h2>Total Equity</h2>
                                                                                </div>
                                                                                <div>
                                                                                    <h3>5.5894 BTC</h3>
                                                                                </div>
                                                                            </li>
                                                                            <li className="d-flex justify-content-between align-items-center">
                                                                                <div
                                                                                    className="d-flex align-items-center">
                                                                                    <i className="icon ion-md-checkmark"></i>
                                                                                    <h2>Available Margin</h2>
                                                                                </div>
                                                                                <div>
                                                                                    <h3>2.480 BTC</h3>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                        <button className="btn green">Deposit</button>
                                                                        <button className="btn red">Withdraw</button>
                                                                    </div>
                                                                </div>
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            Wallet Deposit Address
                                                                        </h5>
                                                                        <div className="row wallet-address">
                                                                            <div className="col-md-8">
                                                                                <p>
                                                                                    Deposits to this address are
                                                                                    unlimited.
                                                                                    Note that you may not be able to
                                                                                    withdraw all of your funds at once
                                                                                    if
                                                                                    you deposit more than your daily
                                                                                    withdrawal limit.
                                                                                </p>
                                                                                <div className="input-group">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        value="Ad87deD4gEe8dG57Ede4eEg5dREs4d5e8f4e"
                                                                                    />
                                                                                    <div
                                                                                        className="input-group-prepend">
                                                                                        <button
                                                                                            className="btn btn-primary">
                                                                                            COPY
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <img
                                                                                    src={'img/qr-code-dark.svg'}
                                                                                    alt="qr-code"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            Latest Transactions
                                                                        </h5>
                                                                        <div className="wallet-history">
                                                                            <table className="table">
                                                                                <thead>
                                                                                <tr>
                                                                                    <th>No.</th>
                                                                                    <th>Date</th>
                                                                                    <th>Status</th>
                                                                                    <th>Amount</th>
                                                                                </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td>25-04-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                                                                                    </td>
                                                                                    <td>4.5454334</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>2</td>
                                                                                    <td>25-05-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                                                                                    </td>
                                                                                    <td>0.5484468</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>3</td>
                                                                                    <td>25-06-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-close-circle-outline red"></i>
                                                                                    </td>
                                                                                    <td>2.5454545</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>4</td>
                                                                                    <td>25-07-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                                                                                    </td>
                                                                                    <td>1.45894147</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>3</td>
                                                                                    <td>25-08-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-close-circle-outline red"></i>
                                                                                    </td>
                                                                                    <td>2.5454545</td>
                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="tab-pane fade"
                                                                id="coinETH"
                                                                role="tabpanel"
                                                            >
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">Balances</h5>
                                                                        <ul>
                                                                            <li className="d-flex justify-content-between align-items-center">
                                                                                <div
                                                                                    className="d-flex align-items-center">
                                                                                    <i className="icon ion-md-cash"></i>
                                                                                    <h2>Total Equity</h2>
                                                                                </div>
                                                                                <div>
                                                                                    <h3>4.1542 ETH</h3>
                                                                                </div>
                                                                            </li>
                                                                            <li className="d-flex justify-content-between align-items-center">
                                                                                <div
                                                                                    className="d-flex align-items-center">
                                                                                    <i className="icon ion-md-checkmark"></i>
                                                                                    <h2>Available Margin</h2>
                                                                                </div>
                                                                                <div>
                                                                                    <h3>1.334 ETH</h3>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                        <button className="btn green">Deposit</button>
                                                                        <button className="btn red">Withdraw</button>
                                                                    </div>
                                                                </div>
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            Wallet Deposit Address
                                                                        </h5>
                                                                        <div className="row wallet-address">
                                                                            <div className="col-md-8">
                                                                                <p>
                                                                                    Deposits to this address are
                                                                                    unlimited.
                                                                                    Note that you may not be able to
                                                                                    withdraw all of your funds at once
                                                                                    if
                                                                                    you deposit more than your daily
                                                                                    withdrawal limit.
                                                                                </p>
                                                                                <div className="input-group">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        value="Ad87deD4gEe8dG57Ede4eEg5dREs4d5e8f4e"
                                                                                    />
                                                                                    <div
                                                                                        className="input-group-prepend">
                                                                                        <button
                                                                                            className="btn btn-primary">
                                                                                            COPY
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <img
                                                                                    src={'img/qr-code-dark.svg'}
                                                                                    alt="qr-code"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            Latest Transactions
                                                                        </h5>
                                                                        <div className="wallet-history">
                                                                            <table className="table">
                                                                                <thead>
                                                                                <tr>
                                                                                    <th>No.</th>
                                                                                    <th>Date</th>
                                                                                    <th>Status</th>
                                                                                    <th>Amount</th>
                                                                                </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td>25-04-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                                                                                    </td>
                                                                                    <td>4.5454334</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>2</td>
                                                                                    <td>25-05-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                                                                                    </td>
                                                                                    <td>0.5484468</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>3</td>
                                                                                    <td>25-06-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-close-circle-outline red"></i>
                                                                                    </td>
                                                                                    <td>2.5454545</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>4</td>
                                                                                    <td>25-07-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                                                                                    </td>
                                                                                    <td>1.45894147</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>3</td>
                                                                                    <td>25-08-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-close-circle-outline red"></i>
                                                                                    </td>
                                                                                    <td>2.5454545</td>
                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="tab-pane fade"
                                                                id="coinBNB"
                                                                role="tabpanel"
                                                            >
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">Balances</h5>
                                                                        <ul>
                                                                            <li className="d-flex justify-content-between align-items-center">
                                                                                <div
                                                                                    className="d-flex align-items-center">
                                                                                    <i className="icon ion-md-cash"></i>
                                                                                    <h2>Total Equity</h2>
                                                                                </div>
                                                                                <div>
                                                                                    <h3>7.342 BNB</h3>
                                                                                </div>
                                                                            </li>
                                                                            <li className="d-flex justify-content-between align-items-center">
                                                                                <div
                                                                                    className="d-flex align-items-center">
                                                                                    <i className="icon ion-md-checkmark"></i>
                                                                                    <h2>Available Margin</h2>
                                                                                </div>
                                                                                <div>
                                                                                    <h3>0.332 BNB</h3>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                        <button className="btn green">Deposit</button>
                                                                        <button className="btn red">Withdraw</button>
                                                                    </div>
                                                                </div>
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            Wallet Deposit Address
                                                                        </h5>
                                                                        <div className="row wallet-address">
                                                                            <div className="col-md-8">
                                                                                <p>
                                                                                    Deposits to this address are
                                                                                    unlimited.
                                                                                    Note that you may not be able to
                                                                                    withdraw all of your funds at once
                                                                                    if
                                                                                    you deposit more than your daily
                                                                                    withdrawal limit.
                                                                                </p>
                                                                                <div className="input-group">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        value="Ad87deD4gEe8dG57Ede4eEg5dREs4d5e8f4e"
                                                                                    />
                                                                                    <div
                                                                                        className="input-group-prepend">
                                                                                        <button
                                                                                            className="btn btn-primary">
                                                                                            COPY
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <img
                                                                                    src={'img/qr-code-dark.svg'}
                                                                                    alt="qr-code"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            Latest Transactions
                                                                        </h5>
                                                                        <div className="wallet-history">
                                                                            <table className="table">
                                                                                <thead>
                                                                                <tr>
                                                                                    <th>No.</th>
                                                                                    <th>Date</th>
                                                                                    <th>Status</th>
                                                                                    <th>Amount</th>
                                                                                </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td>25-04-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                                                                                    </td>
                                                                                    <td>4.5454334</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>2</td>
                                                                                    <td>25-05-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                                                                                    </td>
                                                                                    <td>0.5484468</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>3</td>
                                                                                    <td>25-06-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-close-circle-outline red"></i>
                                                                                    </td>
                                                                                    <td>2.5454545</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>4</td>
                                                                                    <td>25-07-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                                                                                    </td>
                                                                                    <td>1.45894147</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>3</td>
                                                                                    <td>25-08-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-close-circle-outline red"></i>
                                                                                    </td>
                                                                                    <td>2.5454545</td>
                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="tab-pane fade"
                                                                id="coinTRX"
                                                                role="tabpanel"
                                                            >
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">Balances</h5>
                                                                        <ul>
                                                                            <li className="d-flex justify-content-between align-items-center">
                                                                                <div
                                                                                    className="d-flex align-items-center">
                                                                                    <i className="icon ion-md-cash"></i>
                                                                                    <h2>Total Equity</h2>
                                                                                </div>
                                                                                <div>
                                                                                    <h3>4.3344 TRX</h3>
                                                                                </div>
                                                                            </li>
                                                                            <li className="d-flex justify-content-between align-items-center">
                                                                                <div
                                                                                    className="d-flex align-items-center">
                                                                                    <i className="icon ion-md-checkmark"></i>
                                                                                    <h2>Available Margin</h2>
                                                                                </div>
                                                                                <div>
                                                                                    <h3>1.453 TRX</h3>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                        <button className="btn green">Deposit</button>
                                                                        <button className="btn red">Withdraw</button>
                                                                    </div>
                                                                </div>
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            Wallet Deposit Address
                                                                        </h5>
                                                                        <div className="row wallet-address">
                                                                            <div className="col-md-8">
                                                                                <p>
                                                                                    Deposits to this address are
                                                                                    unlimited.
                                                                                    Note that you may not be able to
                                                                                    withdraw all of your funds at once
                                                                                    if
                                                                                    you deposit more than your daily
                                                                                    withdrawal limit.
                                                                                </p>
                                                                                <div className="input-group">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        value="Ad87deD4gEe8dG57Ede4eEg5dREs4d5e8f4e"
                                                                                    />
                                                                                    <div
                                                                                        className="input-group-prepend">
                                                                                        <button
                                                                                            className="btn btn-primary">
                                                                                            COPY
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <img
                                                                                    src={'img/qr-code-dark.svg'}
                                                                                    alt="qr-code"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            Latest Transactions
                                                                        </h5>
                                                                        <div className="wallet-history">
                                                                            <table className="table">
                                                                                <thead>
                                                                                <tr>
                                                                                    <th>No.</th>
                                                                                    <th>Date</th>
                                                                                    <th>Status</th>
                                                                                    <th>Amount</th>
                                                                                </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td>25-04-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                                                                                    </td>
                                                                                    <td>4.5454334</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>2</td>
                                                                                    <td>25-05-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                                                                                    </td>
                                                                                    <td>0.5484468</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>3</td>
                                                                                    <td>25-06-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-close-circle-outline red"></i>
                                                                                    </td>
                                                                                    <td>2.5454545</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>4</td>
                                                                                    <td>25-07-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                                                                                    </td>
                                                                                    <td>1.45894147</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>3</td>
                                                                                    <td>25-08-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-close-circle-outline red"></i>
                                                                                    </td>
                                                                                    <td>2.5454545</td>
                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="tab-pane fade"
                                                                id="coinEOS"
                                                                role="tabpanel"
                                                            >
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">Balances</h5>
                                                                        <ul>
                                                                            <li className="d-flex justify-content-between align-items-center">
                                                                                <div
                                                                                    className="d-flex align-items-center">
                                                                                    <i className="icon ion-md-cash"></i>
                                                                                    <h2>Total Equity</h2>
                                                                                </div>
                                                                                <div>
                                                                                    <h3>33.35 EOS</h3>
                                                                                </div>
                                                                            </li>
                                                                            <li className="d-flex justify-content-between align-items-center">
                                                                                <div
                                                                                    className="d-flex align-items-center">
                                                                                    <i className="icon ion-md-checkmark"></i>
                                                                                    <h2>Available Margin</h2>
                                                                                </div>
                                                                                <div>
                                                                                    <h3>4.445 EOS</h3>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                        <button className="btn green">Deposit</button>
                                                                        <button className="btn red">Withdraw</button>
                                                                    </div>
                                                                </div>
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            Wallet Deposit Address
                                                                        </h5>
                                                                        <div className="row wallet-address">
                                                                            <div className="col-md-8">
                                                                                <p>
                                                                                    Deposits to this address are
                                                                                    unlimited.
                                                                                    Note that you may not be able to
                                                                                    withdraw all of your funds at once
                                                                                    if
                                                                                    you deposit more than your daily
                                                                                    withdrawal limit.
                                                                                </p>
                                                                                <div className="input-group">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        value="Ad87deD4gEe8dG57Ede4eEg5dREs4d5e8f4e"
                                                                                    />
                                                                                    <div
                                                                                        className="input-group-prepend">
                                                                                        <button
                                                                                            className="btn btn-primary">
                                                                                            COPY
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <img
                                                                                    src={'img/qr-code-dark.svg'}
                                                                                    alt="qr-code"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            Latest Transactions
                                                                        </h5>
                                                                        <div className="wallet-history">
                                                                            <table className="table">
                                                                                <thead>
                                                                                <tr>
                                                                                    <th>No.</th>
                                                                                    <th>Date</th>
                                                                                    <th>Status</th>
                                                                                    <th>Amount</th>
                                                                                </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td>25-04-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                                                                                    </td>
                                                                                    <td>4.5454334</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>2</td>
                                                                                    <td>25-05-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                                                                                    </td>
                                                                                    <td>0.5484468</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>3</td>
                                                                                    <td>25-06-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-close-circle-outline red"></i>
                                                                                    </td>
                                                                                    <td>2.5454545</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>4</td>
                                                                                    <td>25-07-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                                                                                    </td>
                                                                                    <td>1.45894147</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>3</td>
                                                                                    <td>25-08-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-close-circle-outline red"></i>
                                                                                    </td>
                                                                                    <td>2.5454545</td>
                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="tab-pane fade"
                                                                id="coinXMR"
                                                                role="tabpanel"
                                                            >
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">Balances</h5>
                                                                        <ul>
                                                                            <li className="d-flex justify-content-between align-items-center">
                                                                                <div
                                                                                    className="d-flex align-items-center">
                                                                                    <i className="icon ion-md-cash"></i>
                                                                                    <h2>Total Equity</h2>
                                                                                </div>
                                                                                <div>
                                                                                    <h3>34.333 XMR</h3>
                                                                                </div>
                                                                            </li>
                                                                            <li className="d-flex justify-content-between align-items-center">
                                                                                <div
                                                                                    className="d-flex align-items-center">
                                                                                    <i className="icon ion-md-checkmark"></i>
                                                                                    <h2>Available Margin</h2>
                                                                                </div>
                                                                                <div>
                                                                                    <h3>2.354 XMR</h3>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                        <button className="btn green">Deposit</button>
                                                                        <button className="btn red">Withdraw</button>
                                                                    </div>
                                                                </div>
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            Wallet Deposit Address
                                                                        </h5>
                                                                        <div className="row wallet-address">
                                                                            <div className="col-md-8">
                                                                                <p>
                                                                                    Deposits to this address are
                                                                                    unlimited.
                                                                                    Note that you may not be able to
                                                                                    withdraw all of your funds at once
                                                                                    if
                                                                                    you deposit more than your daily
                                                                                    withdrawal limit.
                                                                                </p>
                                                                                <div className="input-group">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        value="Ad87deD4gEe8dG57Ede4eEg5dREs4d5e8f4e"
                                                                                    />
                                                                                    <div
                                                                                        className="input-group-prepend">
                                                                                        <button
                                                                                            className="btn btn-primary">
                                                                                            COPY
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <img
                                                                                    src={'img/qr-code-dark.svg'}
                                                                                    alt="qr-code"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            Latest Transactions
                                                                        </h5>
                                                                        <div className="wallet-history">
                                                                            <table className="table">
                                                                                <thead>
                                                                                <tr>
                                                                                    <th>No.</th>
                                                                                    <th>Date</th>
                                                                                    <th>Status</th>
                                                                                    <th>Amount</th>
                                                                                </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td>25-04-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                                                                                    </td>
                                                                                    <td>4.5454334</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>2</td>
                                                                                    <td>25-05-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                                                                                    </td>
                                                                                    <td>0.5484468</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>3</td>
                                                                                    <td>25-06-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-close-circle-outline red"></i>
                                                                                    </td>
                                                                                    <td>2.5454545</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>4</td>
                                                                                    <td>25-07-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                                                                                    </td>
                                                                                    <td>1.45894147</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>3</td>
                                                                                    <td>25-08-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-close-circle-outline red"></i>
                                                                                    </td>
                                                                                    <td>2.5454545</td>
                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="tab-pane fade"
                                                                id="coinKCS"
                                                                role="tabpanel"
                                                            >
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">Balances</h5>
                                                                        <ul>
                                                                            <li className="d-flex justify-content-between align-items-center">
                                                                                <div
                                                                                    className="d-flex align-items-center">
                                                                                    <i className="icon ion-md-cash"></i>
                                                                                    <h2>Total Equity</h2>
                                                                                </div>
                                                                                <div>
                                                                                    <h3>86.577 KCS</h3>
                                                                                </div>
                                                                            </li>
                                                                            <li className="d-flex justify-content-between align-items-center">
                                                                                <div
                                                                                    className="d-flex align-items-center">
                                                                                    <i className="icon ion-md-checkmark"></i>
                                                                                    <h2>Available Margin</h2>
                                                                                </div>
                                                                                <div>
                                                                                    <h3>5.78 KCS</h3>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                        <button className="btn green">Deposit</button>
                                                                        <button className="btn red">Withdraw</button>
                                                                    </div>
                                                                </div>
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            Wallet Deposit Address
                                                                        </h5>
                                                                        <div className="row wallet-address">
                                                                            <div className="col-md-8">
                                                                                <p>
                                                                                    Deposits to this address are
                                                                                    unlimited.
                                                                                    Note that you may not be able to
                                                                                    withdraw all of your funds at once
                                                                                    if
                                                                                    you deposit more than your daily
                                                                                    withdrawal limit.
                                                                                </p>
                                                                                <div className="input-group">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        value="Ad87deD4gEe8dG57Ede4eEg5dREs4d5e8f4e"
                                                                                    />
                                                                                    <div
                                                                                        className="input-group-prepend">
                                                                                        <button
                                                                                            className="btn btn-primary">
                                                                                            COPY
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <img
                                                                                    src={'img/qr-code-dark.svg'}
                                                                                    alt="qr-code"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            Latest Transactions
                                                                        </h5>
                                                                        <div className="wallet-history">
                                                                            <table className="table">
                                                                                <thead>
                                                                                <tr>
                                                                                    <th>No.</th>
                                                                                    <th>Date</th>
                                                                                    <th>Status</th>
                                                                                    <th>Amount</th>
                                                                                </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td>25-04-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                                                                                    </td>
                                                                                    <td>4.5454334</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>2</td>
                                                                                    <td>25-05-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                                                                                    </td>
                                                                                    <td>0.5484468</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>3</td>
                                                                                    <td>25-06-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-close-circle-outline red"></i>
                                                                                    </td>
                                                                                    <td>2.5454545</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>4</td>
                                                                                    <td>25-07-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                                                                                    </td>
                                                                                    <td>1.45894147</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>3</td>
                                                                                    <td>25-08-2019</td>
                                                                                    <td>
                                                                                        <i className="icon ion-md-close-circle-outline red"></i>
                                                                                    </td>
                                                                                    <td>2.5454545</td>
                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="settings">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">Notifications</h5>
                                                    <div className="settings-notification">
                                                        <ul>
                                                            <li>
                                                                <div className="notification-info">
                                                                    <p>Update price</p>
                                                                    <span>
                                  Get the update price in your dashboard
                                </span>
                                                                </div>
                                                                <div className="custom-control custom-switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        id="notification1"
                                                                    />
                                                                    <label
                                                                        className="custom-control-label"
                                                                        htmlFor="notification1"
                                                                    ></label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="notification-info">
                                                                    <p>2FA</p>
                                                                    <span>
                                  Unable two factor authentication service
                                </span>
                                                                </div>
                                                                <div className="custom-control custom-switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        id="notification2"
                                                                        checked
                                                                    />
                                                                    <label
                                                                        className="custom-control-label"
                                                                        htmlFor="notification2"
                                                                    ></label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="notification-info">
                                                                    <p>Latest news</p>
                                                                    <span>Get the latest news in your mail</span>
                                                                </div>
                                                                <div className="custom-control custom-switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        id="notification3"
                                                                    />
                                                                    <label
                                                                        className="custom-control-label"
                                                                        htmlFor="notification3"
                                                                    ></label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="notification-info">
                                                                    <p>Email Service</p>
                                                                    <span>Get security code in your mail</span>
                                                                </div>
                                                                <div className="custom-control custom-switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        id="notification4"
                                                                        checked
                                                                    />
                                                                    <label
                                                                        className="custom-control-label"
                                                                        htmlFor="notification4"
                                                                    ></label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="notification-info">
                                                                    <p>Phone Notify</p>
                                                                    <span>
                                  Get transition notification in your phone{' '}
                                </span>
                                                                </div>
                                                                <div className="custom-control custom-switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        id="notification5"
                                                                        checked
                                                                    />
                                                                    <label
                                                                        className="custom-control-label"
                                                                        htmlFor="notification5"
                                                                    ></label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card settings-profile">
                                                <div className="card-body">
                                                    <h5 className="card-title">Create API Key</h5>
                                                    <div className="form-row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="generateKey">
                                                                Generate key name
                                                            </label>
                                                            <input
                                                                id="generateKey"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter your key name"
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label htmlFor="rewritePassword">
                                                                Confirm password
                                                            </label>
                                                            <input
                                                                id="rewritePassword"
                                                                type="password"
                                                                className="form-control"
                                                                placeholder="Confirm your password"
                                                            />
                                                        </div>
                                                        <div className="col-md-12">
                                                            <input type="submit" value="Create API key"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">Your API Keys</h5>
                                                    <div className="wallet-history">
                                                        <table className="table">
                                                            <thead>
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>Key</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                <td>1</td>
                                                                <td>zRmWVcrAZ1C0RZkFMu7K5v0KWC9jUJLt</td>
                                                                <td>
                                                                    <div className="custom-control custom-switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            id="apiStatus1"
                                                                            checked
                                                                        />
                                                                        <label
                                                                            className="custom-control-label"
                                                                            htmlFor="apiStatus1"
                                                                        ></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <i className="icon ion-md-trash"></i>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>Rv5dgnKdmVPyHwxeExBYz8uFwYQz3Jvg</td>
                                                                <td>
                                                                    <div className="custom-control custom-switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            id="apiStatus2"
                                                                        />
                                                                        <label
                                                                            className="custom-control-label"
                                                                            htmlFor="apiStatus2"
                                                                        ></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <i className="icon ion-md-trash"></i>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>VxEYIs1HwgmtKTUMA4aknjSEjjePZIWu</td>
                                                                <td>
                                                                    <div className="custom-control custom-switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            id="apiStatus3"
                                                                        />
                                                                        <label
                                                                            className="custom-control-label"
                                                                            htmlFor="apiStatus3"
                                                                        ></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <i className="icon ion-md-trash"></i>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>M01DueJ4x3awI1SSLGT3CP1EeLSnqt8o</td>
                                                                <td>
                                                                    <div className="custom-control custom-switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            id="apiStatus4"
                                                                        />
                                                                        <label
                                                                            className="custom-control-label"
                                                                            htmlFor="apiStatus4"
                                                                        ></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <i className="icon ion-md-trash"></i>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </div>
            </>
        )
    }

const mapStateToProps = (store) => ({
    userData: store.user.userData
})
export default connect(mapStateToProps)(Profile)
